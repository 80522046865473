.card-filter {
  background-color: whitesmoke;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 15px;
  cursor: pointer;
}

.card-body-filter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-filter#all {
  color: #2d2d2d;
}

.card-filter#conekta {
  background-color: rgba(228, 5, 149, 0.8);
}

.card-filter#paypal {
  background-color: #00a1de;
}

.card-filter#oxxo {
  background-color: #00a1de;
}

.card-filter#oxxo {
  background-color: rgba(218, 19, 19, 0.822);
}

.header-title {
  margin-top: 120px;
}

.box-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.information-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 35%;
  margin: 0px 15px 0px 0px;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #e9e9e9;
  margin: 5px 0px 50px 0px;
  font-weight: 600;
  font-size: 18px;
  color: rgb(78, 78, 78);
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.information-label {
  color: #999;
}
