.views__btn {
  border: none;
  background-color: #d4cfcf;
  color: #2d2d2d;
  cursor: pointer;
  padding: 0.6rem 1.2rem;
  border-radius: 0.4rem;
  max-width: 200px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 10px;
}
.views__btn:hover {
  background-color: rgba(230, 226, 225, 0.8);
}
.views__btn:disabled {
  background-color: #d4cfcf;
  cursor: pointer;
}

.views__icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.views__number {
  font-size: 1rem;
}
