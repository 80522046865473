.like__btn {
  border: none;
  background-color: #e74c3c;
  color: white;
  cursor: pointer;
  padding: 0.6rem 1.2rem;
  border-radius: 0.4rem;
  max-width: 200px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.like__btn:hover {
  background-color: rgba(231, 76, 60, 0.8);
}
.like__btn:disabled {
  background-color: rgba(231, 77, 60, 0.589);
  cursor: pointer;
}

.like__icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.like__number {
  font-size: 1rem;
}
