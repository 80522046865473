/* grid items */

.grid figure {
  margin: 0;
  position: relative;
  height: 200px;
}

.grid figure img {
  max-width: 100%;
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
}

.grid figcaption {
  position: absolute;
  top: 0;
  left: 0;
  /* padding: 20px 0px 20px; */
  background: #000000a8;
  color: #ffffff;
}

.grid figcaption h3 {
  margin: 0;
  padding: 0;
  color: #fff;
}

.grid figcaption a {
  text-align: center;
  padding: 5px 10px;
  border-radius: 2px;
  display: inline-block;
  background: var(--theme-color);
  color: #fff;
}

/* media query for smaller screens */
@media screen and (max-width: 31.5em) {
  .grid {
    padding: 10px 10px 100px 10px;
  }
  .grid li {
    width: 100%;
    min-width: 300px;
  }
}

/* caption-effect-1 */

.caption-effect-1 figcaption {
  height: 100%;
  width: 100%;
  opacity: 0;
  text-align: center;
  backface-visibility: hidden;
  /* transition: transform 0.3s, opacity 0.3s; */
}

.no-touch .caption-effect-1 figure:hover figcaption,
.caption-effect-1 figure.cs-hover figcaption {
  opacity: 1;
  /* transform: translate(15px, 15px); */
}

.no-touch .caption-effect-1 figure:hover figcaption h3,
.caption-effect-1 figure.cs-hover figcaption h3 {
  font-size: 20px !important;
  /* transform: translate(15px, 15px); */
}
.no-touch .caption-effect-1 figure:hover figcaption span,
.caption-effect-1 figure.cs-hover figcaption span {
  margin-top: 6px;
  font-size: 13px !important;
  /* transform: translate(15px, 15px); */
}

/* .caption-effect-1 figcaption h3 { */
/* margin-top: 70px; */
/* } */

.caption-effect-1 figcaption span {
  display: block;
}

.caption-effect-1 figcaption a {
  margin-top: 30px;
}

/* caption-effect-2 */

.caption-effect-2 figure img {
  z-index: 10;
  /* transition: transform 0.4s; */
}

.no-touch .caption-effect-2 figure:hover img,
.caption-effect-2 figure.cs-hover img {
  transform: translateY(-90px);
}

.caption-effect-2 figcaption {
  height: 90px;
  width: 100%;
  top: auto;
  bottom: 0;
}

.caption-effect-2 figcaption a {
  position: absolute;
  right: 20px;
  top: 30px;
}

/* caption-effect-3 */

.caption-effect-3 figure {
  overflow: hidden;
}

.caption-effect-3 figure img {
  transition: transform 0.4s;
}

.no-touch .caption-effect-3 figure:hover img,
.caption-effect-3 figure.cs-hover img {
  transform: translateY(-50px);
}

.caption-effect-3 figcaption {
  height: 100px;
  width: 100%;
  top: auto;
  bottom: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.4s, opacity 0.1s 0.3s;
}

.no-touch .caption-effect-3 figure:hover figcaption,
.caption-effect-3 figure.cs-hover figcaption {
  opacity: 1;
  transform: translateY(0px);
  transition: transform 0.4s, opacity 0.1s;
}

.caption-effect-3 figcaption a {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

/* caption-effect-4 */

.caption-effect-4 li {
  perspective: 1700px;
  perspective-origin: 0 50%;
}

.caption-effect-4 figure {
  transform-style: preserve-3d;
}

.caption-effect-4 figure > div {
  overflow: hidden;
}

.caption-effect-4 figure img {
  transition: transform 0.4s;
}

.no-touch .caption-effect-4 figure:hover img,
.caption-effect-4 figure.cs-hover img {
  transform: translateX(25%);
}

.caption-effect-4 figcaption {
  height: 100%;
  width: 50%;
  opacity: 0;
  backface-visibility: hidden;
  transform-origin: 0 0;
  transform: rotateY(-90deg);
  transition: transform 0.4s, opacity 0.1s 0.3s;
}

.no-touch .caption-effect-4 figure:hover figcaption,
.caption-effect-4 figure.cs-hover figcaption {
  opacity: 1;
  transform: rotateY(0deg);
  transition: transform 0.4s, opacity 0.1s;
}

.caption-effect-4 figcaption a {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

/* caption-effect-5 */

.caption-effect-5 figure img {
  z-index: 10;
  transition: transform 0.4s;
}

.no-touch .caption-effect-5 figure:hover img,
.caption-effect-5 figure.cs-hover img {
  transform: scale(0.4);
}

.caption-effect-5 figcaption {
  height: 100%;
  width: 100%;
  opacity: 0;
  transform: scale(0.7);
  backface-visibility: hidden;
  transition: transform 0.4s, opacity 0.4s;
}

.no-touch .caption-effect-5 figure:hover figcaption,
.caption-effect-5 figure.cs-hover figcaption {
  transform: scale(1);
  opacity: 1;
}

.caption-effect-5 figure a {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
