input,
select,
textarea {
  width: 100%;
}

table input,
table select {
  width: auto;
}

input[type='radio'],
input[type='checkbox'] {
  width: auto;
  margin-right: 5px;
}

.ck-content.ck-editor__editable {
  height: 40vh !important;
}

.oauth-bar {
  margin: 10px;
  padding: 10px;
}

.login-button {
  margin: 10px;
}

.oauth-bar .login-button > span {
  border-radius: 8px;
  display: block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
}

/* Twitter */
.twitter {
  border: 3px solid #ffffff;
  background: #326ada;
  color: white;
}

/* Google */
.google {
  border: 3px solid #ffffff;
  background: #c4463d;
  color: white;
}

.facebook {
  border: 3px solid #ffffff;
  background: #3b5998;
  color: white;
}

.table tbody tr td:nth-child(odd) {
  background: white;
}

.table tbody tr:hover td {
  background: #ececec;
}

.table tbody tr td {
  border: 1px solid rgba(206, 204, 204, 0.959) !important;
}

.background-hero {
  background-color: #414040b6;
  background-blend-mode: soft-light;
}

.background-hero * {
  color: white !important;
}

nav * {
  color: white !important;
}

.dropdown-menu.show * {
  color: black !important;
}

.campaign-content {
  word-break: break-word;
}

.campaign-content iframe {
  width: 100%;
}

.campaign-content img {
  max-width: 100%;
  object-fit: cover;
}

.campaign-slider .card-img-top {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.campaign-slider .card-body {
  height: 180px;
}

.slick-prev:before {
  color: var(--theme-color) !important;
  background-color: #eee;
}
.slick-next:before {
  color: var(--theme-color) !important;
  background-color: #eee;
}

.slick-dots * {
  fill: var(--theme-color);
}

.hero-wrap.hero-wrap-2 {
  height: 100vh !important;
}

.social-media-share-bar > * {
  margin: auto 5px;
}

.ftco-navbar-light {
  z-index: 1000;
}

textarea.form-control {
  border-width: 1px;
  border-color: #2d2d2d;
  font-size: 15px;
}

.search-label {
  width: 100%;
}

#campaign-individual a,
#campaign-individual a:hover {
  color: var(--theme-color) !important;
}

.ftco-counter .text strong.number > * {
  font-size: 40px;
  color: white;
}

.ftco-counter .text span {
  font-size: 25px;
}

.menu li {
  background: var(--theme-color) !important;
  margin: 5px;
}

.no-touch .menu li a:hover,
.no-touch .menu li a:active,
.no-touch .menu li a:focus {
  border-bottom: 4px solid var(--theme-darker-color) !important;
}

.ftco_navbar.scrolled .nav-link.navbar-brand > span,
.ftco_navbar .nav-link.navbar-brand > img,
#ftco-navbar.admin .nav-link.navbar-brand > span {
  display: none !important;
}

.ftco_navbar.scrolled .nav-link.navbar-brand > img {
  display: block !important;
}

#table-toolbar .search-bar {
  background: whitesmoke !important;
  height: 40px !important;
  font-size: 16px;
}

.download-excel-button {
  background: #2aa05f;
  border-radius: 10px;
  height: 40px;
  border-color: #1d6f42;
  outline-color: #1d6f42;
}

.download-excel-button:hover {
  background: #1d6f42;
}

.react-calendar {
  border: 1px solid rgba(199, 198, 198, 0.788) !important;
  padding: 20px;
  width: 95% !important;
  border-radius: 10px;
}

.overflow-box::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.overflow-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.overflow-box::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
}

/* Handle on hover */
.overflow-box::-webkit-scrollbar-thumb:hover {
  background: rgb(128, 126, 126);
}

.object-fit-cover {
  object-fit: cover;
}

a:hover,
button:hover {
  cursor: pointer !important;
}

.swal2-file,
.swal2-input,
.swal2-textarea {
  font-size: 1em;
}

@media (max-width: 768px) and (max-width: 991.98px) {
  .hero-wrap .slider-text h1 span {
    font-size: 41px;
    text-align: left;
  }

  .hero-wrap .slider-text p {
    font-size: 19px !important;
    text-align: left;
  }

  .hero-wrap .slider-text a {
    font-size: 19px;
  }

  .js-fullheight {
    height: 100%;
  }

  .hero-wrap.hero-wrap-2,
  .carousel-inner {
    height: 60vh !important;
  }

  .hero-wrap .slider-text p {
    font-size: 19px !important;
    text-align: left;
  }

  .ftco-counter .text strong.number > * {
    font-size: 35px !important;
  }

  .ftco-navbar-light {
    min-height: 77px;
  }
}

.ftco-footer {
  padding-top: 4em;
  padding-bottom: 2em;
}

@media (max-width: 575.98px) {
  .hero-wrap .slider-text h1 span {
    font-size: 25px;
    text-align: left;
  }

  .hero-wrap .slider-text p {
    font-size: 18px !important;
    text-align: left;
  }

  .hero-wrap .slider-text a {
    font-size: 19px;
  }

  .hero-wrap.hero-wrap-2,
  .carousel-inner {
    height: 90vh !important;
  }

  .hero-wrap * {
    text-align: left !important;
  }

  .hero-wrap p {
    margin-bottom: 40px;
  }

  .one-forth {
    align-items: center !important;
  }

  .one-forth > * {
    min-width: 100% !important;
  }

  .ftco-navbar-light {
    min-height: unset;
  }
}

.ftco-section {
  padding-bottom: 2em !important;
}

.input-search input {
  background-color: whitesmoke !important;
}

/* .filters {
  display: flex;
  justify-content: center;
  width: 95%;
} */

.filters input,
.filters select {
  background-color: #e4e8f0 !important;
  outline: 0 !important;
  border: 0 !important;
  /* font-family: 'Noto Sans JP', sans-serif; */
  /* font-size: 85%; */
  border-radius: 10px;
  height: 100%;
  /* padding: 20px; */
}
.filters .button,
.filters .button:hover {
  background-color: var(--theme-color);
  color: #ffffff;
  height: 100%;
  width: 20%;
  border-radius: 5px;
  border: 0;

}

.filters .button:hover {
  background-color: var(--theme-darker-color);
}

.search-bar,
.search-bar input,
.search-bar button {
  height: 40px !important;
}

.grid-alert{
  display: flex;
  text-align: center;
  flex-direction: column;
  }

.grid-btn-search{
  position: absolute;
right: 15px;
top: 0px;
border: none;
}
.grid-btn-search i{
  color: #807e7d !important;
}

.grid-btn-search i:hover{
  color:var(--theme-color ) !important;
  border: none !important;
}

.grid-btn-search:focus{
  box-shadow: none !important;
}

.swal-text {
  text-align: center;
}
