.initial-configuration {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.form-wrapper .form-header {
  font-size: 15px;
}

.form-wrapper .form-footer {
  font-size: 14px;
}

.form-wrapper .form-body {
  min-height: 380px;
}

.form-wrapper #logo {
  margin-bottom: 30px;
}

.form-wrapper input[type='text'],
.form-wrapper input[type='email'],
.form-wrapper input[type='number'],
.form-wrapper input[type='password'] {
  border-color: rgba(85, 85, 85, 0.719);
  height: 40px !important;
}

.form-wrapper button {
  border-radius: 5px;
  margin-top: 20px;
}

.form-wrapper #prev-button {
  float: left;
}

.form-wrapper #next-button {
  float: right;
}

.initial-configuration > .form-wrapper {
  width: 600px;
  height: 90vh;
}

.initial-configuration ::-webkit-scrollbar {
  width: 10px;
}

.initial-configuration ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.initial-configuration ::-webkit-scrollbar-thumb {
  background: #888;
}

.initial-configuration ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
