.donation-card p {
  color: #777;
}

.donation-card div {
  display: -webkit-box;
  display: flex;
}

.donation-card .row {
  margin: -7.5px;
}

.donation-card .row .col {
  padding: 0 7.5px;
}

.donation-card {
  /* max-width: 350px; */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.donation-card .donations-needed {
  background-color: #424242;
  padding: 15px;
  border-radius: 5px;
  position: relative;
}

.donation-card .donations-needed:after {
  content: '';
  position: absolute;
  bottom: -10px;
  right: 25px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #424242;
}

.donation-card .donations-needed p {
  color: white;
  text-align: center;
  margin-bottom: 0;
  width: 100%;
}

.donation-card .indicator-wrap {
  -webkit-box-flex: 1;
  flex: 1;
  border: 1px solid #eaeaea;
  border-bottom-width: 0;
  margin-top: 15px;
}
.indicator-wrap #indicator {
  height: 20px;
  background-color: var(--theme-color);
}

.donation-card .card {
  width: 100%;
  padding: 15px;
  border-radius: 0;
  margin-bottom: 15px;
  border-color: #eaeaea;
}

.donation-card .card .orange-text {
  color: var(--theme-color);
  font-weight: bold;
}

.donation-card .card .form-row {
  margin-top: 7.5px;
}

.donation-card .card #donation-input {
  width: 100%;
  padding-left: 15px;
  font-weight: bold;
  color: #424242;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}

.donation-card .card #give-btn {
  /* background: #1cbc2c; */
  border: 0;
  width: 100%;
  padding: 12px 0;
  font-weight: bold;
}

.donation-card .card #give-btn:hover {
  background: #4c18c4;
  color: white;
}

.donation-card .card .why-give {
  margin-top: 15px;
  color: #20a1d4;
  font-style: italic;
  margin-bottom: 0;
}

.donation-card .card #error {
  color: var(--theme-color);
  font-size: 10px;
}

.donation-card .card #error.hidden {
  display: none;
}

.donation-card .btn-grey {
  width: 100%;
  background: rgba(234, 234, 234, 0.3);
  font-weight: bold;
  color: #424242;
  border: 1px solid #eaeaea;
  border-bottom-width: 2px;
}

.card-payment {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: auto;
  flex-wrap: wrap;
}

.card-payment > * {
  margin: 20px;
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

.donation-buttons {
  display: flex;
  flex-direction: column;
}

.donation-buttons > * {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.cursor-pointer {
  cursor: pointer;
}
